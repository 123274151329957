.Notification{
    position: fixed; 
    right: 0;
    bottom: 0;
    z-index: 55555;
}

.Notification > div {
    /*margin:10px; 
    width: 200px; 
    height: 100px; 
    border-radius: 3px;
    box-shadow: 0 0 3px #000;
    word-wrap: break-word;
    padding: 10px;*/
}

.Success {
    background: green;
    color: white;
}

.Alert {
    background: orange;
    color: black;
}

.Danger{
    background: red;
    color: white;
}