.outerTab{
    display: inline-block;
    box-sizing: border-box;
    text-align: center;
    border-left: 1px solid #aaa;
    border-right: 1px solid #aaa;
}

.innerTab{
    display: inline-block;
    box-sizing: border-box;
    padding: 5px 15px;
    border-top: 1px solid #aaa;
}

.InfoBar{
    height: 56px;
    padding: 8px 16px;
    box-sizing: border-box;
    background: #eee;
    border-bottom: 1px solid #aaa;
}

.InfoBarLeft{
    float: left;
}

.InfoBarLeft h4, .InfoBarLeft h5{
    margin: 0;
}

.InfoBarRight{
    float: right;
}

.tabBar{
    border: 1px solid #aaa;
    background: #FFF;
}

.firstTab{
    display: inline-block;
    box-sizing: border-box;
    text-align: center;
    width: 200px;
}

.TopBar{
    position:fixed;
    top:0;
    left:0;
    width: 100%;
    z-index: 5;
}

.workspace{
    overflow: hidden;
    height: calc(100vh - 80px);
    margin-top: 80px;
}