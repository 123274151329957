.container{
    padding: 16px;
    display: flex;
}

.SingleEntry, .Profile{
    border: 1px solid #aaa;
    width: 400px;
    box-sizing: border-box;
    margin: 0 20px;
}

.listContainer{
    border:1px solid;
}

.SingleEntry > *{
    display: block;
    padding:8px;
    border-bottom: 1px solid #aaa;
    box-sizing: border-box;
}