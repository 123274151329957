.editorContainer{
    border: 1px solid #aaa;
    padding:5px;
    width: 600px;
}
.editors{
    border-top:1px solid #aaa;
    padding-top: 5px;
    margin-top: 5px;
    overflow: hidden;
}
.fullwidth{
    width: 100%;
}
.expand{
    position:fixed;
    z-index: 5000;
    top: 0;
    left: 0;
    width:100vw;
    height:100vh;
    background: #fff;
    overflow:hidden;
    display:flex;
    flex-direction: column;
}

.expand .editorDiv{
    overflow: auto;
    flex-grow:1;
    margin-bottom: 40px;
}

.noselect {
    -webkit-touch-callout: none; /* iOS Safari */
      -webkit-user-select: none; /* Safari */
       -khtml-user-select: none; /* Konqueror HTML */
         -moz-user-select: none; /* Old versions of Firefox */
          -ms-user-select: none; /* Internet Explorer/Edge */
              user-select: none; /* Non-prefixed version, currently
                                    supported by Chrome, Opera and Firefox */
  }

.dropDownContainer{
    position: relative;
}
.dropDownSelector{
    height: 40px;
    line-height: 40px;
    padding: 0 20px;
    border: 1px solid #888;
    background: #eee;
    border-radius: 5px;
    cursor: pointer;
}
.dropDownList{
    position: absolute;
    top: 45px;
    right: 0;
    width: 250px;
    max-height: 500px;
    overflow: auto;
    border: 1px solid #888;
    box-shadow: 1px 1px 3px rgba(0,0,0,0.3);
    border-radius: 5px;
    background: #FFF;
    z-index: 5;
}