.container{
    padding: 16px;
    display: flex;
}

.SingleEntry{
    border: 1px solid #aaa;
    width: 400px;
    box-sizing: border-box;
}

.listContainer{
    border:1px solid;
}

.SingleEntry > *{
    display: block;
    padding:8px;
    border-bottom: 1px solid #aaa;
    box-sizing: border-box;
}

.demoContainer{
    position:fixed;
    left:5px;
    width:295px;
    height:calc(100vh - 200px);
    background:#fafafa;
    box-sizing:border-box;
    overflow-y: auto;
    overflow-x: hidden;
    z-index: 5555;
    padding: 5px;
    top: 135px;
}

.infoHtmlContainer{
    position:fixed;
    right:25px;
    width:200px;
    height:calc(100vh - 200px);
    background:#fafafa;
    box-sizing:border-box;
    overflow-y: auto;
    overflow-x: hidden;
    z-index: 5555;
    padding: 5px;
    top: 135px;
}

.topRight{
    position: fixed;
    right:0;
    top: 0;
    box-sizing: border-box;
    z-index: 5555;
    padding: 5px;
}

.backPopUp{
    width: 260px;
    background: #007bcc;
    position: absolute;
    z-index: 200;
    bottom: 40px;
    border-radius: 3px;
    box-shadow: 0 0 2px rgba(0,0,0,0.3);
    padding: 10px;
    color: #FFF;
    font-weight:600;
    display:none;
}

.leftPane, .rightPane{
    width: 50%;
    float: left;
    height: 100vh;
    
}

.leftPane{
    padding: 5px;
    overflow: auto;
}

.rightPane{
    border-left: 1px groove #fff;
    padding: 0;
}

.workarea{
    padding: 20px;
    height: calc(100vh - 65px);
    overflow: auto;
}

.OCRForm :global(.editorClass){
    width: 100%;
}

.OCRForm :global(.addPageBut){
    width: 100px;
}

.OCRForm :global(.addPageBut div){
    position: absolute;
    z-index: 5;
}

.OCRForm :global(.formItemsWrapper){
    float: none;
}

.OCRForm :global(.formSubmitButton){
    position: fixed;
    bottom: 15px;
    z-index: 5;
} 

.OCRForm :global(.optionContainer){
    position: fixed;
    z-index: 5;
    border: 1px solid #CCC;
} 

.OCRForm :global(.optionContainerHeightFix){
    display: block;
}