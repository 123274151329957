.Button{
    min-width:unset;
    min-height:unset;
    width: unset;
    height: unset;
    cursor: pointer;
    display: inline-block;
    padding: 5px 8px;
    box-sizing: border-box;
}
.Button.disable, .Button.loader{
    border: 2px solid #aaa;
    background: #ccc;
    color: #666;
    text-align: center;
    position: relative;
    cursor: auto;
}
.Button.loader span{
    visibility: hidden;
}
.Button.loader div.loaderContainer{
    position: absolute;
    margin: 0;
    top: 50%;
    left: 50%;
    z-index: 1;
    transform: translate(-50%, -50%);
}