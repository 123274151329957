.taskWithoutDemo{
    width: calc(100% - 50px);
}

.taskWithoutDemo :global(.formItemsWrapper){
    display: flex;
}

.taskWithoutDemo :global(.form-group){
    margin-bottom: 0;
    width: 0;
}

.taskWithoutDemo :global(.form-group:first-child){
    flex-grow: 1;
    width: auto;
}

.taskWithoutDemo :global(.form-group:nth-child(n+1) .form-label){
    display:none;
}

.taskWithoutDemo :global(.formSubmitButton){
    align-self: flex-end
}