.filterBar{
    width: 400px;
    box-sizing: border-box;
    position: fixed;
    height: calc(100vh - 6em);
    z-index: 5;
    transition: 0.5s;
    overflow-y:auto;
    overflow-x:hidden;
    background: #FFF;
    top: 80px;
}

.filterBarRight{
    margin-left: 400px;
    transition: margin-left .5s;
    box-sizing: border-box;
}