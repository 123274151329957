.container{
    display: flex;
    flex-direction: row;
    
}

.leftBar{
    width: 250px;
    box-sizing: border-box;
    border-right: 1px solid #ddd;
    position: fixed;
    left: 0;
    background: #eee;
    z-index: 5;
    transition: 0.5s;
    overflow-y:auto;
    overflow-x:hidden;
    height:calc(100vh - 80px);
}

.rightBar{
    margin-left: 250px;
    transition: margin-left .5s;
    box-sizing: border-box;
    overflow-x:auto;
    height:calc(100vh - 80px);
}

.MenuItem{
    display:block;
    padding: 8px 16px;
    border-bottom:1px solid #ddd;
    background: #eee;
    color: #555;
    font-weight: 600;
}

.leftBar .active{
    background: #333;
    color: white;
    
}