.arrows{
    cursor:pointer;
    font-size: 28px;
    line-height: 0;
    padding-left:10px;
    padding-right: 10px;
}

.pageInput{
    width:40px;height:20px;
}

.separator{
    margin-left: 15px;
    margin-right: 15px;
}

.zoom{
    cursor:pointer;
    padding-left:10px;
    padding-right: 10px;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.noselect {
    -webkit-touch-callout: none; /* iOS Safari */
      -webkit-user-select: none; /* Safari */
       -khtml-user-select: none; /* Konqueror HTML */
         -moz-user-select: none; /* Old versions of Firefox */
          -ms-user-select: none; /* Internet Explorer/Edge */
              user-select: none; /* Non-prefixed version, currently
                                    supported by Chrome, Opera and Firefox */
  }