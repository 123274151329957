.fileLineCountHead {
    background: #eee;
}
.fileLineCountNum {
    border: 1px solid #444;
}
.fLRow{
    margin-bottom: 10px;
}
.mainContainer{
    padding: 20px;
}
.singleField{
    width: 20%;
    display: inline-block;
    padding: 0 10px;
}
.singleListItem{
    padding: 10px 20px !important;
    background-color: #f5f5cc !important;
}
.singleListSubItem{
    padding: 5px 20px 5px 30px;
}
.errorText{
    position: absolute;
    background: #FFF;
    padding: 5px;
}
.MenuItem{
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    padding: 10px 20px;
    font-size: 14px;
    background-color: #00C2BA;
    color: #222;
    display: inline-block;
}
.menuActive{
    background-color: #82E0BF;
}