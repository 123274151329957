.container{
    padding: 16px;
    display: flex;
}

.listContainer{
    border:1px solid;
}

.SingleEntry{
    border: 1px solid #aaa;
    width: 400px;
    box-sizing: border-box;
}

.SingleEntry > *{
    display: block;
    padding:8px;
    border-bottom: 1px solid #aaa;
    box-sizing: border-box;
}

.active{
    border: 3px solid black;
}